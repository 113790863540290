// Copyright 2025 The LUCI Authors.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//      http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import { ModeSpec } from 'codemirror';

export const DEFAULT_CODE_MIRROR_CONFIG = {
  mode: { name: 'javascript', json: true } as ModeSpec<{ json: boolean }>,
  readOnly: true,
  matchBrackets: true,
  lineWrapping: true,
  foldGutter: true,
  lineNumbers: true,
  gutters: ['CodeMirror-linenumbers', 'CodeMirror-foldgutter'],
};
